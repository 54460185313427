import { navigate } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import PageLayout from '../components/components/page-layout'
import { AnchorButton, Section, StackedLogo } from '../components/elements'
import { Alert } from '../components/grouping'
import { useAppState } from '../state/app-context'
import { Page } from '../types/page'

const GetStartedTemplate = ({ pageContext }: Page.TemplateGetStartedProperties) => {
  const { language } = pageContext
  const { firstTimeUser, appLanguage, channel, region, store } = useAppState()
  const [loading, setLoading] = useState(true)

  const setupRequired = useMemo(() => {
    return !appLanguage || !channel || !region || !store
  }, [appLanguage, channel, region, store])

  useEffect(() => {
    if (firstTimeUser) {
      navigate(`/${language}/onboarding/`)
    } else {
      setLoading(false)
    }
  }, [firstTimeUser, language])

  return (
    <PageLayout lang={language} theme={loading ? 'light' : 'dark'} className="c__header--hide-logo">
      {!loading && (
        <Section className="c__get-started-template">
          <StackedLogo className="animated animated--fade-in-up" />
          {setupRequired ? (
            <>
              <AnchorButton
                href={`/${language}/setup`}
                variant="primary"
                className="animated animated--fade-in-up animated-delay-2"
              >
                Setup store
              </AnchorButton>

              <Alert className="animated animated--fade-in-up animated-delay-3">
                There are missing store information. Please go to Settings and update the store information.
              </Alert>
            </>
          ) : (
            <AnchorButton
              href={`/${language}/bottle-selection`}
              variant="primary"
              className="animated animated--fade-in-up animated-delay-2"
            >
              Get Started
            </AnchorButton>
          )}
        </Section>
      )}
    </PageLayout>
  )
}

export default GetStartedTemplate
